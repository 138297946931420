<template>
  <div style="position: relative">
    <Breadcrumb :Breadcrumb="BreadcrumbCon" />
    <div class="search">
      <el-select
        v-model="valueArea"
        filterable
        clearable
        placeholder="请选择区号(可搜索关键字)"
        style="width: 240px"
        v-if="internationType"
      >
        <el-option
          v-for="item in options"
          :key="item.name"
          :label="item.name + item.tel"
          :value="item.tel"
        >
        </el-option>
      </el-select>
      <el-input
        v-model="phone"
        width="200"
        placeholder="请输入手机号"
      ></el-input>
      <el-button type="primary" @click="searchList">查询</el-button>
      <el-button type="info" @click="reset">重置</el-button>
    </div>

    <div>
      <el-table
        :data="tableData"
        style="width: 100%"
        border
        :header-cell-style="{ background: '#e5e5e5', color: '#4b4b4b' }"
      >
        <el-table-column type="index" label="序号" width="80" align="center">
          <template slot-scope="scope">
            <span>{{ scope.$index + (currentPage - 1) * pageSize + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="orderId"
          label="订单编号"
          align="center"
          width="130"
        ></el-table-column>
        <el-table-column
          prop="phoneArea"
          label="区号"
          align="center"
          width="80"
        >
        </el-table-column>
        <el-table-column prop="phone" label="手机号" align="center" width="130">
        </el-table-column>
        <el-table-column
          prop="contacts"
          label="收货人"
          align="center"
          width="130"
        >
        </el-table-column>
        <el-table-column
          prop="number"
          label="收货人手机号"
          width="130"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="address" label="收货地址" width="240">
        </el-table-column>
        <el-table-column prop="oldBoxId" label="旧趣宝盒ID" width="180">
        </el-table-column>
        <el-table-column prop="newBoxId" label="新趣宝盒ID" width="180">
        </el-table-column>
        <el-table-column
          prop="expressNumber"
          label="快递单号"
          width="180"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="p95Amount" label="实付款" align="center">
          <template slot-scope="scope">
            {{
              scope.row.payType == 1
                ? scope.row.amountTotal
                : scope.row.p95Amount
            }}
          </template>
        </el-table-column>
        <el-table-column prop="p05Amount" label="抵扣金额" align="center">
          <template slot-scope="scope">
            {{ scope.row.payType == 10 ? scope.row.p05Amount : "0" }}
          </template>
        </el-table-column>
        <el-table-column prop="p05Cple" label="消耗cple" align="center">
          <template slot-scope="scope">
            {{ scope.row.payType == 10 ? scope.row.p05Cple : "0" }}
          </template>
        </el-table-column>
        <el-table-column prop="orderStatus" label="交易状态" align="center">
          <template slot-scope="scope">
            <div>{{ scope.row.orderStatus }}</div>
            <!-- <el-button size="mini" type="text" @click="goDetail(scope.row)"
            >查看详情</el-button
          > -->
          </template>
        </el-table-column>

        <el-table-column
          prop="createTime"
          label="下单时间"
          width="180"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="shipTime"
          label="发货时间"
          width="180"
          align="center"
        >
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="100" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              style="color: #67c23a"
              v-clipboard:copy="messageCopy"
              v-clipboard:success="onCopy"
              v-clipboard:error="onError"
              @click="handleCopy(scope.row)"
              >复制发货信息</el-button
            >

            <div v-if="scope.row.orderStatus == '已发货'">
              <el-button
                size="mini"
                type="text"
                @click="handleAgainDeliery(scope.row)"
                >重新发货</el-button
              >

              <el-dialog
                title="重新发货"
                :visible.sync="dialogFormVisibleAgain"
                :modal-append-to-body="false"
              >
                <el-form :model="formAgain" :rules="rules" ref="formAgain">
                  <el-form-item
                    label="快递单号"
                    :label-width="formLabelWidth"
                    prop="name"
                  >
                    <el-input
                      v-model="formAgain.name"
                      autocomplete="off"
                    ></el-input>
                  </el-form-item>
                  <el-form-item
                    label="快递公司"
                    :label-width="formLabelWidth"
                    prop="region"
                  >
                    <el-select
                      v-model="formAgain.region"
                      placeholder="请选择快递公司"
                    >
                      <el-option label="顺丰快递" value="顺丰快递"></el-option>
                      <el-option label="京东物流" value="京东物流"></el-option>
                      <el-option label="中通快递" value="中通快递"></el-option>
                      <el-option label="韵达快递" value="韵达快递"></el-option>
                      <el-option label="EMS" value="EMS"></el-option>
                      <el-option label="圆通快递" value="圆通快递"></el-option>
                      <el-option label="申通快递" value="申通快递"></el-option>
                      <el-option label="极兔快递" value="极兔快递"></el-option>
                      <el-option label="德邦快递" value="德邦快递"></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item
                    label="新盒子id"
                    :label-width="formLabelWidth"
                    prop="boxId"
                  >
                    <el-input
                      v-model="formAgain.boxId"
                      autocomplete="off"
                    ></el-input>
                  </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                  <el-button @click="dialogFormVisible = false"
                    >取 消</el-button
                  >
                  <el-button
                    type="primary"
                    @click="handleAgainConfirm('formAgain')"
                    >确 定</el-button
                  >
                </div>
              </el-dialog>
            </div>

            <div v-if="scope.row.orderStatus == '已发货'">
              <el-button
                size="mini"
                type="text"
                @click="handleReceive(scope.row)"
                >标记已收货</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>

      <Pagination
        v-show="pageLength >= 0"
        :total="pageLength"
        :limit.sync="pageSize"
        @pagination="handlePageChange"
        :page-sizes="pageSizes"
      />
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/BreadCrumb";
import Pagination from "@/components/Pagination";

import { areaCodeData } from "@/util/areaCodeData";

import { reqUrl } from "@/api/apiUrl";
import { request } from "@/api/request";
import { showLoading, hideLoading } from "@/util/loading";

export default {
  inject: ["reload"],
  components: { Breadcrumb, Pagination },

  data() {
    return {
      BreadcrumbCon: [
        {
          name: "行程上链",
        },
        {
          name: "趣宝盒数据",
        },
        {
          name: "以旧领新",
        },
        {
          name: "待绑定订单",
        },
      ], // 面包屑数据
      tableData: [],

      dialogFormVisible: false,
      dialogFormVisibleAgain: false,
      expressOrderId: "",
      expressOrderIdAgain: "",

      form: {
        name: "",
        region: "",
        boxId: "",
      },
      formLabelWidth: "120px",

      formAgain: {
        name: "",
        region: "",
        boxId: "",
      },
      rules: {
        name: [{ required: true, message: "请输入快递单号", trigger: "blur" }],
        region: [
          { required: true, message: "请选择快递公司", trigger: "blur" },
        ],
        boxId: [{ required: true, message: "请输入新盒子id", trigger: "blur" }],
      },

      messageCopy: "",

      option: [],
      valueArea: "",
      phone: "",
      internationType: false,
      role: "",
      phoneAreaROLE: "",

      // 分页数据
      currentPage: 1, // 当前页
      pageLength: 0,
      pageSize: 10, //要传过去的数据 每页多少条数据
      pageSizes: [10, 50, 100, 200, 500, 1000],
    };
  },

  mounted() {
    this.options = areaCodeData;
    this.role = localStorage.getItem("ROLE");

    var strNation = this.role.includes("ROLE_nation");
    if (strNation) {
      this.internationType = true;
    }
    this.phoneAreaROLE = localStorage.getItem("phoneArea");

    showLoading();
    const opt = {
      url: reqUrl.listShippedOrder,
      method: "POST",
      params: JSON.stringify({
        pageNum: "",
        pageSize: "",
      }),
      resFunc: (res) => {
        console.log(res.data);
        if (res.data.code != 0) {
          this.$notify.error({
            title: "错误",
            message: res.data.message,
          });
          hideLoading();
        } else if (res.data.code == 0) {
          hideLoading();
          let arrList = [];
          res.data.data.list.map((item) => {
            const { orderId } = item.mallOrderInfo;

            let obj = {};
            obj = {
              ...item.addressInfo,
              ...item.boxRenewDTO,
              ...item.goodsInfo,
              ...item.mallOrderInfo,
              ...item.payOrderInfo,
              ...item.skuInfo,
            };
            obj.orderIdcopy = orderId;

            arrList.push(obj);
          });
          this.tableData = arrList;

          arrList.map((item) => {
            if (item.isCancel) {
              item.orderStatus = "已取消";
            } else if (item.isPaid == false) {
              item.orderStatus = "等待买家付款";
            } else if (item.isPaid == true && item.isShip == false) {
              item.orderStatus = "待发货";
            } else if (
              item.isPaid == true &&
              item.isShip == true &&
              item.isConfirm == false
            ) {
              item.orderStatus = "已发货";
            } else if (
              item.isPaid == true &&
              item.isShip == true &&
              item.isConfirm == true
            ) {
              item.orderStatus = "已签收";
            }

            item.createTime = new Date(item.createTime).toLocaleString();
            item.shipTime = new Date(item.shipTime).toLocaleString();
          });
          this.pageLength = res.data.data.total;
          this.pageSize = res.data.data.pageSize;
        }
        hideLoading();
      },
      errFunc: (err) => {
        this.$notify.error({
          title: "错误",
          message: err,
        });
        hideLoading();
        console.log(err, "err1");
      },
    };
    request(opt);
  },

  methods: {
    searchList() {
      showLoading();
      const opt = {
        url: reqUrl.listShippedOrder,
        method: "POST",
        params: JSON.stringify({
          phone: this.phone,
          phoneArea: this.valueArea || this.phoneAreaROLE,
        }),
        resFunc: (res) => {
          console.log(res.data);
          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
            hideLoading();
          } else if (res.data.code == 0) {
            hideLoading();
            let arrList = [];
            res.data.data.list.map((item) => {
              const { orderId } = item.mallOrderInfo;

              let obj = {};
              obj = {
                ...item.addressInfo,
                ...item.boxRenewDTO,
                ...item.goodsInfo,
                ...item.mallOrderInfo,
                ...item.payOrderInfo,
                ...item.skuInfo,
              };
              obj.orderIdcopy = orderId;

              arrList.push(obj);
            });
            this.tableData = arrList;

            arrList.map((item) => {
              if (item.isCancel) {
                item.orderStatus = "已取消";
              } else if (item.isPaid == false) {
                item.orderStatus = "等待买家付款";
              } else if (item.isPaid == true && item.isShip == false) {
                item.orderStatus = "待发货";
              } else if (
                item.isPaid == true &&
                item.isShip == true &&
                item.isConfirm == false
              ) {
                item.orderStatus = "已发货";
              } else if (
                item.isPaid == true &&
                item.isShip == true &&
                item.isConfirm == true
              ) {
                item.orderStatus = "已签收";
              }

              item.createTime = new Date(item.createTime).toLocaleString();
              item.shipTime = new Date(item.shipTime).toLocaleString();
            });

            this.pageLength = res.data.data.total;
            this.pageSize = res.data.data.pageSize;
          }
          hideLoading();
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          hideLoading();
          console.log(err, "err1");
        },
      };
      request(opt);
    },

    handlePageChange(data) {
      showLoading();
      const opt = {
        url: reqUrl.listShippedOrder,
        method: "POST",
        params: JSON.stringify({
          phone: this.phone,
          phoneArea: this.valueArea || this.phoneAreaROLE,
          pageNum: data.pageIndex,
          pageSize: data.pageSize,
        }),
        resFunc: (res) => {
          console.log(res.data);
          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
            hideLoading();
          } else if (res.data.code == 0) {
            hideLoading();
            let arrList = [];
            res.data.data.list.map((item) => {
              const { orderId } = item.mallOrderInfo;

              let obj = {};
              obj = {
                ...item.addressInfo,
                ...item.boxRenewDTO,
                ...item.goodsInfo,
                ...item.mallOrderInfo,
                ...item.payOrderInfo,
                ...item.skuInfo,
              };
              obj.orderIdcopy = orderId;

              arrList.push(obj);
            });
            this.tableData = arrList;

            arrList.map((item) => {
              if (item.isCancel) {
                item.orderStatus = "已取消";
              } else if (item.isPaid == false) {
                item.orderStatus = "等待买家付款";
              } else if (item.isPaid == true && item.isShip == false) {
                item.orderStatus = "待发货";
              } else if (
                item.isPaid == true &&
                item.isShip == true &&
                item.isConfirm == false
              ) {
                item.orderStatus = "已发货";
              } else if (
                item.isPaid == true &&
                item.isShip == true &&
                item.isConfirm == true
              ) {
                item.orderStatus = "已签收";
              }
              item.createTime = new Date(item.createTime).toLocaleString();
              item.shipTime = new Date(item.shipTime).toLocaleString();
            });

            this.pageLength = res.data.data.total;
            this.pageSize = res.data.data.pageSize;
            this.currentPage = data.pageIndex;
          }
          hideLoading();
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          hideLoading();
          console.log(err, "err1");
        },
      };
      request(opt);
    },

    // 标记收货
    handleReceive(row) {
      this.$confirm("您确定要进行确认收货操作吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          showLoading();
          const opt = {
            url: reqUrl.confirmOrder,
            method: "POST",
            params: JSON.stringify({
              orderId: row.orderIdcopy,
            }),
            resFunc: (res) => {
              console.log(res.data);
              if (res.data.code != 0) {
                this.$notify.error({
                  title: "错误",
                  message: res.data.message,
                });
                hideLoading();
              } else if (res.data.code == 0) {
                hideLoading();
                this.$message({
                  message: "确认收货操作成功",
                  type: "success",
                });

                this.refresh();
              }
              hideLoading();
            },
            errFunc: (err) => {
              this.$notify.error({
                title: "错误",
                message: err,
              });
              hideLoading();
              console.log(err, "err1");
            },
          };
          request(opt);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },

    // 重新发货
    handleAgainDeliery(row) {
      this.dialogFormVisibleAgain = true;
      this.expressOrderIdAgain = row.orderIdcopy;
    },

    handleAgainConfirm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          showLoading();
          const opt = {
            url: reqUrl.shipBoxOrderAgain,
            method: "POST",
            params: JSON.stringify({
              orderId: this.expressOrderIdAgain,
              expressName: this.formAgain.region,
              expressNum: this.formAgain.name,
              boxId: this.formAgain.boxId,
            }),
            resFunc: (res) => {
              console.log(res.data);
              if (res.data.code != 0) {
                this.$notify.error({
                  title: "错误",
                  message: res.data.message,
                });
                hideLoading();
              } else if (res.data.code == 0) {
                hideLoading();
                this.$message({
                  message: "重新发货操作成功",
                  type: "success",
                });
                this.dialogFormVisibleAgain = false;

                this.refresh();
              }
              hideLoading();
            },
            errFunc: (err) => {
              this.$notify.error({
                title: "错误",
                message: err,
              });
              hideLoading();
              console.log(err, "err1");
            },
          };
          request(opt);
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    onCopy() {
      this.$message.success("复制地址成功");
    },
    onError() {
      this.$message.error("复制地址失败");
    },

    handleCopy(row) {
      this.messageCopy = `发货信息：${row.contacts} ${row.number} ${row.address}`;
    },

    reset() {
      this.phone = "";
      this.phoneArea = "";
      this.refresh();
    },

    // 刷新
    refresh() {
      this.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  display: flex;
  margin-bottom: 8px;

  .el-input {
    width: 300px;
    margin: 0 10px;
  }
}

::v-deep .el-tabs__content {
  overflow: scroll;
}
</style>
